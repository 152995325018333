import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentTextField.figmaUrl.ios} codeUrl={checklists.componentTextField.codeUrl.ios} checklists={checklists.componentTextField.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`The Base Text Field component in the Legion iOS UIKit provides two types of fields: Outline and Inline. Each type of field has five states (default, focused, error, success, disabled) and supports additional features such as left icons, labels (title text field), captions, prefixes, and suffixes.`}</p>
    <h3>{`Importing The Theme`}</h3>
    <p>{`To use the Legion iOS UIKit theme, you need to import one of the available themes. Currently supported themes are as follows: `}<inlineCode parentName="p">{`ThemeAGR`}</inlineCode>{`, `}<inlineCode parentName="p">{`ThemeEazy`}</inlineCode>{`, `}<inlineCode parentName="p">{`ThemeIHS`}</inlineCode>{`, `}<inlineCode parentName="p">{`ThemeLGN`}</inlineCode>{`, and `}<inlineCode parentName="p">{`ThemeMyTEnS`}</inlineCode>{`. `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import ThemeLGN
`}</code></pre>
    <div className="divi" />
    <h2>{`Style`}</h2>
    <h3>{`Inline`}</h3>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-uikit/ios-uikit-textfield-inline-state-default.png",
      "alt": null,
      "title": "LGNTextField Inline"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let textField = LGNTextField()
textField.style = .inline
`}</code></pre>
    <h3>{`Outline`}</h3>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-uikit/ios-uikit-textfield-outline-state-default.png",
      "alt": null,
      "title": "LGNTextField Outline"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let textField = LGNTextField()
textField.style = .outline
`}</code></pre>
    <div className="divi" />
    <h2>{`Size`}</h2>
    <p>{`The LGNTextField offers three size variants: small (`}<inlineCode parentName="p">{`.sm`}</inlineCode>{`), medium (`}<inlineCode parentName="p">{`.md`}</inlineCode>{`), and large (`}<inlineCode parentName="p">{`.lg`}</inlineCode>{`).`}</p>
    <h3>{`Large`}</h3>
    <h4>{`Inline`}</h4>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-uikit/ios-uikit-textfield-inline-size-lg.png",
      "alt": null,
      "title": "LGNTextField Inline Large"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let textField = LGNTextField()
textField.size = .lg
textField.style = .inline
`}</code></pre>
    <h4>{`Outline`}</h4>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-uikit/ios-uikit-textfield-outline-size-lg.png",
      "alt": null,
      "title": "LGNTextField Outline Large"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let textField = LGNTextField()
textField.size = .lg
textField.style = .outline
`}</code></pre>
    <h3>{`Medium`}</h3>
    <h4>{`Inline`}</h4>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-uikit/ios-uikit-textfield-inline-size-md.png",
      "alt": null,
      "title": "LGNTextField Inline Medium"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let textField = LGNTextField()
textField.size = .md
textField.style = .inline
`}</code></pre>
    <h4>{`Outline`}</h4>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-uikit/ios-uikit-textfield-outline-size-md.png",
      "alt": null,
      "title": "LGNTextField Outline Medium"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let textField = LGNTextField()
textField.size = .md
textField.style = .outline
`}</code></pre>
    <h3>{`Small`}</h3>
    <h4>{`Inline`}</h4>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-uikit/ios-uikit-textfield-inline-size-sm.png",
      "alt": null,
      "title": "LGNTextField Inline Small"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let textField = LGNTextField()
textField.size = .sm
textField.style = .inline
`}</code></pre>
    <h4>{`Outline`}</h4>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-uikit/ios-uikit-textfield-outline-size-sm.png",
      "alt": null,
      "title": "LGNTextField Outline Small"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let textField = LGNTextField()
textField.size = .sm
textField.style = .outline
`}</code></pre>
    <div className="divi" />
    <h2>{`State`}</h2>
    <p>{`The LGNTextField offer five state variants ranging from `}<inlineCode parentName="p">{`Default`}</inlineCode>{`, `}<inlineCode parentName="p">{`Focused`}</inlineCode>{`, `}<inlineCode parentName="p">{`Error`}</inlineCode>{`, `}<inlineCode parentName="p">{`Success`}</inlineCode>{`, and `}<inlineCode parentName="p">{`Disabled`}</inlineCode>{`.`}</p>
    <h3>{`Default`}</h3>
    <h4>{`Inline`}</h4>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-uikit/ios-uikit-textfield-inline-state-default.png",
      "alt": null,
      "title": "LGNTextField Inline Default"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let textField = LGNTextField()
textField.setState(.default)
textField.style = .inline
`}</code></pre>
    <h4>{`Outline`}</h4>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-uikit/ios-uikit-textfield-outline-state-default.png",
      "alt": null,
      "title": "LGNTextField Outline Default"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let textField = LGNTextField()
textField.setState(.default)
textField.style = .outline
`}</code></pre>
    <h3>{`Focused`}</h3>
    <h4>{`Inline`}</h4>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-uikit/ios-uikit-textfield-inline-state-focused.png",
      "alt": null,
      "title": "LGNTextField Inline Focused"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let textField = LGNTextField()
textField.setState(.focused)
textField.style = .inline
`}</code></pre>
    <h4>{`Outline`}</h4>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-uikit/ios-uikit-textfield-outline-state-focused.png",
      "alt": null,
      "title": "LGNTextField Outline Focused"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let textField = LGNTextField()
textField.setState(.focused)
textField.style = .outline
`}</code></pre>
    <h3>{`Error`}</h3>
    <h4>{`Inline`}</h4>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-uikit/ios-uikit-textfield-inline-state-error.png",
      "alt": null,
      "title": "LGNTextField Inline Error"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let textField = LGNTextField()
textField.setState(.error)
textField.style = .inline
`}</code></pre>
    <h4>{`Outline`}</h4>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-uikit/ios-uikit-textfield-outline-state-error.png",
      "alt": null,
      "title": "LGNTextField Outline Error"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let textField = LGNTextField()
textField.setState(.error)
textField.style = .outline
`}</code></pre>
    <h3>{`Success`}</h3>
    <h4>{`Inline`}</h4>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-uikit/ios-uikit-textfield-inline-state-success.png",
      "alt": null,
      "title": "LGNTextField Inline Success"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let textField = LGNTextField()
textField.setState(.success)
textField.style = .inline
`}</code></pre>
    <h4>{`Outline`}</h4>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-uikit/ios-uikit-textfield-outline-state-success.png",
      "alt": null,
      "title": "LGNTextField Outline Success"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let textField = LGNTextField()
textField.setState(.success)
textField.style = .outline
`}</code></pre>
    <h3>{`Disabled`}</h3>
    <h4>{`Inline`}</h4>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-uikit/ios-uikit-textfield-inline-state-disabled.png",
      "alt": null,
      "title": "LGNTextField Inline Disabled"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let textField = LGNTextField()
textField.setState(.disabled)
textField.style = .inline
`}</code></pre>
    <h4>{`Outline`}</h4>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-uikit/ios-uikit-textfield-outline-state-disabled.png",
      "alt": null,
      "title": "LGNTextField Outline Disabled"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let textField = LGNTextField()
textField.setState(.disabled)
textField.style = .outline
`}</code></pre>
    <div className="divi" />
    <h2>{`Icon`}</h2>
    <h3>{`Left Icon`}</h3>
    <h4>{`Inline`}</h4>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-uikit/ios-uikit-textfield-inline-left-icon.png",
      "alt": null,
      "title": "LGNTextField Inline Left Icon"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let textField = LGNTextField()
textField.leftIconSelect = UIImage(systemName: "envelope.fill")
textField.style = .inline
`}</code></pre>
    <h4>{`Outline`}</h4>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-uikit/ios-uikit-textfield-outline-left-icon.png",
      "alt": null,
      "title": "LGNTextField Outline Left Icon"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let textField = LGNTextField()
textField.leftIconSelect = UIImage(systemName: "envelope.fill")
textField.style = .outline
`}</code></pre>
    <h3>{`Right Icon`}</h3>
    <h4>{`Inline`}</h4>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-uikit/ios-uikit-textfield-inline-right-icon.png",
      "alt": null,
      "title": "LGNTextField Inline Right Icon"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let textField = LGNTextField()
textField.rightIconSelect = UIImage(systemName: "xmark.circle.fill")
textField.style = .inline
`}</code></pre>
    <h4>{`Outline`}</h4>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-uikit/ios-uikit-textfield-outline-right-icon.png",
      "alt": null,
      "title": "LGNTextField Outline Right Icon"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let textField = LGNTextField()
textField.rightIconSelect = UIImage(systemName: "xmark.circle.fill")
textField.style = .outline
`}</code></pre>
    <div className="divi" />
    <h2>{`Prefix`}</h2>
    <h3>{`No Icon`}</h3>
    <h4>{`Inline`}</h4>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-uikit/ios-uikit-textfield-inline-prefix.png",
      "alt": null,
      "title": "LGNTextField Inline Prefix No Icon"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let textField = LGNTextField()
textField.textPrefix = "Prefix"
textField.style = .inline
`}</code></pre>
    <h4>{`Outline`}</h4>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-uikit/ios-uikit-textfield-outline-prefix.png",
      "alt": null,
      "title": "LGNTextField Outline Prefix No Icon"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let textField = LGNTextField()
textField.textPrefix = "Prefix"
textField.style = .outline
`}</code></pre>
    <h3>{`With Left Icon`}</h3>
    <h4>{`Inline`}</h4>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-uikit/ios-uikit-textfield-inline-prefix-left-icon.png",
      "alt": null,
      "title": "LGNTextField Inline Prefix with Left Icon"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let textField = LGNTextField()
textField.textPrefix = "Prefix"
textField.leftIconSelect = UIImage(systemName: "envelope.fill")
textField.style = .inline
`}</code></pre>
    <h4>{`Outline`}</h4>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-uikit/ios-uikit-textfield-outline-prefix-left-icon.png",
      "alt": null,
      "title": "LGNTextField Outline Prefix with Left Icon"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let textField = LGNTextField()
textField.textPrefix = "Prefix"
textField.leftIconSelect = UIImage(systemName: "envelope.fill")
textField.style = .outline
`}</code></pre>
    <div className="divi" />
    <h2>{`Suffix`}</h2>
    <h3>{`No Icon`}</h3>
    <h4>{`Inline`}</h4>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-uikit/ios-uikit-textfield-inline-suffix.png",
      "alt": null,
      "title": "LGNTextField Inline Suffix No Icon"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let textField = LGNTextField()
textField.textSuffix = "Suffix"
textField.style = .inline
`}</code></pre>
    <h4>{`Outline`}</h4>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-uikit/ios-uikit-textfield-outline-suffix.png",
      "alt": null,
      "title": "LGNTextField Outline Suffix No Icon"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let textField = LGNTextField()
textField.textSuffix = "Suffix"
textField.style = .outline
`}</code></pre>
    <h3>{`With Right Icon`}</h3>
    <h4>{`Inline`}</h4>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-uikit/ios-uikit-textfield-inline-suffix-right-icon.png",
      "alt": null,
      "title": "LGNTextField Inline Suffix with Right Icon"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let textField = LGNTextField()
textField.textSuffix = "Suffix"
textField.rightIconSelect = UIImage(systemName: "xmark.circle.fill")
textField.style = .inline
`}</code></pre>
    <h4>{`Outline`}</h4>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-uikit/ios-uikit-textfield-outline-suffix-right-icon.png",
      "alt": null,
      "title": "LGNTextField Outline Suffix with Right Icon"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let textField = LGNTextField()
textField.textSuffix = "Suffix"
textField.rightIconSelect = UIImage(systemName: "xmark.circle.fill")
textField.style = .outline
`}</code></pre>
    <div className="divi" />
    <h2>{`Secure Text`}</h2>
    <h3>{`Inline`}</h3>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-uikit/ios-uikit-textfield-inline-secure.png",
      "alt": null,
      "title": "LGNTextField Inline Secure"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let textField = LGNTextField()
textField.isSecure = true
textField.style = .inline
`}</code></pre>
    <h3>{`Outline`}</h3>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-uikit/ios-uikit-textfield-outline-secure.png",
      "alt": null,
      "title": "LGNTextField Outline Secure"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let textField = LGNTextField()
textField.isSecure = true
textField.style = .outline
`}</code></pre>
    <div className="divi" />
    <h2>{`Label`}</h2>
    <p>{`Label is an additional title for Textfield.`}</p>
    <h3>{`Inline`}</h3>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-uikit/ios-uikit-textfield-inline-label.png",
      "alt": null,
      "title": "LGNTextField Inline with Label"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let textField = LGNTextField()
textField.label(
    isHidden: false, 
    textLabel: "Label", 
    required: true
)
textField.style = .inline
`}</code></pre>
    <h3>{`Outline`}</h3>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-uikit/ios-uikit-textfield-outline-label.png",
      "alt": null,
      "title": "LGNTextField Outline with Label"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let textField = LGNTextField()
textField.label(
    isHidden: false, 
    textLabel: "Label", 
    required: true
)
textField.style = .outline
`}</code></pre>
    <div className="divi" />
    <h2>{`Caption`}</h2>
    <h3>{`With Image`}</h3>
    <h4>{`Inline`}</h4>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-uikit/ios-uikit-textfield-inline-caption-with-image.png",
      "alt": null,
      "title": "LGNTextField Inline Caption with Image"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let textField = LGNTextField()
textField.caption(
    isHidden: false,
    icon: UIImage(systemName: "info.circle.fill"),
    caption: "Information",
    iconColor: UIColor(hex: "475467"),
    textColor: UIColor(hex: "475467")
)
textField.style = .inline
`}</code></pre>
    <h4>{`Outline`}</h4>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-uikit/ios-uikit-textfield-outline-caption-with-image.png",
      "alt": null,
      "title": "LGNTextField Outline Caption with Image"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let textField = LGNTextField()
textField.caption(
    isHidden: false,
    icon: UIImage(systemName: "info.circle.fill"),
    caption: "Information",
    iconColor: UIColor(hex: "475467"),
    textColor: UIColor(hex: "475467")
)
textField.style = .outline
`}</code></pre>
    <h3>{`Without Image`}</h3>
    <h4>{`Inline`}</h4>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-uikit/ios-uikit-textfield-inline-caption-without-image.png",
      "alt": null,
      "title": "LGNTextField Inline Caption without Image"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let textField = LGNTextField()
textField.caption(
    isHidden: false,
    caption: "Information",
    textColor: UIColor(hex: "475467")
)
textField.style = .inline
`}</code></pre>
    <h4>{`Outline`}</h4>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-uikit/ios-uikit-textfield-outline-caption-without-image.png",
      "alt": null,
      "title": "LGNTextField Outline Caption without Image"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let textField = LGNTextField()
textField.caption(
    isHidden: false,
    caption: "Information",
    textColor: UIColor(hex: "475467")
)
textField.style = .outline
`}</code></pre>
    <div className="divi" />
    <h2>{`Hint`}</h2>
    <h3>{`Inline`}</h3>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-uikit/ios-uikit-textfield-inline-hint.png",
      "alt": null,
      "title": "LGNTextField Inline Hint"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let textField = LGNTextField()
textField.hintText(
    isHidden: false, 
    textHint: "Hint Text"
)
textField.style = .inline
`}</code></pre>
    <h3>{`Outline`}</h3>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-uikit/ios-uikit-textfield-outline-hint.png",
      "alt": null,
      "title": "LGNTextField Outline Hint"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let textField = LGNTextField()
textField.hintText(
    isHidden: false, 
    textHint: "Hint Text"
)
textField.style = .outline
`}</code></pre>
    <div className="divi" />
    <h2>{`Full Configuration`}</h2>
    <h3>{`Inline`}</h3>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-uikit/ios-uikit-textfield-inline-full-configuration.png",
      "alt": null,
      "title": "LGNTextField Outline Full Configuration"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let textField = LGNTextField()
textField.size = .md
textField.label(
    isHidden: false,
    textLabel: "Label",
    required: true
)
textField.textPrefix = "Prefix"
textField.leftIconSelect = UIImage(systemName: "envelope.fill")
textField.rightIconSelect = UIImage(systemName: "xmark.circle.fill")
textField.textSuffix = "Suffix"
textField.placeholder = "Placeholder"
textField.setState(.success)
textField.caption(
    isHidden: false,
    type: .success,
    caption: "Success"
)
textField.hintText(
    isHidden: false,
    textHint: "Hint Text"
)
textField.onTextChanged = { text in
    print("Input: \\(text)")
}
textField.style = .inline
`}</code></pre>
    <h3>{`Outline`}</h3>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-uikit/ios-uikit-textfield-outline-full-configuration.png",
      "alt": null,
      "title": "LGNTextField Outline Full Configuration"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let textField = LGNTextField()
textField.size = .md
textField.label(
    isHidden: false,
    textLabel: "Label",
    required: true
)
textField.textPrefix = "Prefix"
textField.leftIconSelect = UIImage(systemName: "envelope.fill")
textField.rightIconSelect = UIImage(systemName: "xmark.circle.fill")
textField.textSuffix = "Suffix"
textField.placeholder = "Placeholder"
textField.setState(.success)
textField.caption(
    isHidden: false,
    type: .success,
    caption: "Success"
)
textField.hintText(
    isHidden: false,
    textHint: "Hint Text"
)
textField.onTextChanged = { text in
    print("Input: \\(text)")
}
textField.style = .outline
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      